/*
 *
 *   EGRET - Angular Material Design Admin Template
 *
 *
*/
.mat-column-actions {
  max-width: 100px;
  justify-content: center;
}
.property-card {
  margin: 10px;
  flex-basis: calc(33.333% - 20px);
  mat-icon {
    font-size: 14px;
    height: auto;
  }
}
.bg-transparent {
  background: rgba(0,18,46,0.8) !important;
}

.image-container {
  height: 100%;
  max-height: 200px;
  width: auto;
  overflow: hidden;
}

.image-container img {
  width: auto;
  height: 100%;
  aspect-ratio: 1 / 1;
}

.image-container mat-icon {
  font-size: 50px;
  color: #bdbdbd;
}

.description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.text-normal {
  font-size: 18px;
}

.text-normal img {
  display: block
}

.price-button {
  font-size: 14px;
  line-height: 1 !important;
  padding: 5px 10px !important;

  strong {
    font-size: 20px;
  }
}

.content-public {
  padding-top: 0;
}

// // Third pirty style files
.qr-code-content img {
  width: 100% !important;
  height: auto !important;
}

.ar-1-1 {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.ar-3-2 {
  aspect-ratio: 3 / 2;
  object-fit: cover;
}

.ar-4-3 {
  aspect-ratio: 4 / 3;
  object-fit: cover;
}

.ar-16-9 {
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.ar-2-3 {
  aspect-ratio: 2 / 3;
  object-fit: cover;
}

.ar-3-4 {
  aspect-ratio: 3 / 4;
  object-fit: cover;
}

.ar-9-16 {
  aspect-ratio: 9 / 16;
  object-fit: cover;
}


.list {
  display: flex;
  align-items: center;
  mat-icon {
    line-height: 1 !important;
    height: auto !important;
    min-width: 24px;
    margin: 0 8px 0 0
  }
}
.vertical-align-middle {
  vertical-align: middle;
}
.text-small-btn {
  font-size: 8px;
}
// // Page loader Topbar
@import '../../vendor/pace/pace-theme-min.css';


// Main Variables File
@import "scss/variables";

@import "scss/mixins/mixins";
@import "scss/colors";

@import "scss/animations";

// Open _main.scss and keep files which are required for your current layout.
@import "scss/main/main";

// Angular material theme files
@import "scss/themes/init";

@import "scss/utilities/utilities";
@import "scss/components/components";
@import "scss/palette";


@import "scss/page-layouts";

// Open _views.scss and remove unnecessary view related files
@import "scss/views/views";

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import "~leaflet/dist/leaflet.css";
