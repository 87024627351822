@mixin header-top($theme) {
  $color: map-get($theme, color);
  $text-color: map-get(map-get($color, foreground), text);
  $background: map-get($color, background);
  $background-card: map-get($background, card);
  $background-default: map-get($background, background);

  .header-topnav, .topnav ul ul {
    background-color: white;
    color: #1d5993;
  }
  .bg-blue {
    background-color: white;
    color: #1d5993;
    font-family: 'Mulish';
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 14px; /* 77.778% */
    letter-spacing: 2.7px;
  }
  .top-marquee {
    color: #FFF;
    background: #1d5993;
    font-family: 'Mulish';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    letter-spacing: 1.52px;
  }
  .header-topnav a {
    color: #1d5993;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.08px;
  }
  .banner-700 {
    display: block;
    width: 703px;
    height: 90px;
    flex-shrink: 0;
    background: #F0F0F0;
  }
}
