.header-topnav {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 0;
  z-index: 999;
  .container {
    padding: 0;
  }
  .topbar-branding {
    float: left;
    height: 80px;
    padding: 0 8px;
    margin: 0 8px;
    img {
      height: auto;
      width: 200px;
      margin: 10px 0;
    }
  }
  .topnav {
    display: flex;
    align-items: center;
  }
  .header-topnav-right {
    float: right;
    height: 80px;
    display: flex;
    align-items: center;
    padding-right: 0.67rem;
  }
}

@media (max-width: 959px) {
  .header-topnav-right {
    position: absolute;
    right: 6px;
    top: 0;
  }
}

[dir="rtl"] {
  .header-topnav {
    .topnav {
      flex-direction: row-reverse;
    }
  }
}
